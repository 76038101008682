<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nuevo usuario</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Display Name -->
        <vs-input @blur="user.displayName= trimString(user.displayName)" label="Nombre completo"
                  v-model="user.displayName"
                  class="w-full" name="name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Email -->
        <vs-input @blur="user.email= trimString(user.email)" label="Email" v-model.number="user.email"
                  class="mt-5 w-full" name="email"
                  v-validate="{required: true, email: true}"/>
        <span class="text-danger text-sm"
              v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <!-- Password -->
        <vs-input @blur="user.password= trimString(user.password)" label="Password"
                  v-model="user.password"
                  class="mt-5 w-full" name="password"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('password')">{{ errors.first('password') }}</span>

        <!-- Roles -->
        <vs-select v-model="user.role"
                   label="Rol"
                   class="mt-5 w-full" name="role">
          <vs-select-item value="admin" text="Administrador"/>
          <!--<vs-select-item value="editor" text="Editor"/>-->
        </vs-select>
        <span class="text-danger text-sm"
              v-show="errors.has('role')">{{ errors.first('role') }}</span>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'
import trimString from "../../mixins/trimString"

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data() {
    return {
      user: {
        displayName: null,
        email: null,
        password: null,
        role: null
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          try {
            this.progress = true
            //Create in Auth
            let token = await firebase.auth().currentUser.getIdTokenResult()
            let response = await this.$http.post('https://southamerica-east1-plain-simple-b852a.cloudfunctions.net/createUser', {
              ...this.user,
              idToken: token.token
            })
            //
            //Create in Firestore
            delete this.user.password
            let newUser = await db.collection('users').add({
              ...this.user,
              uid: response.data.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp()
            })
            //

            let obj = {
              id: newUser.id,
              ...this.user,
              uid: response.data.uid
            }

            await this.$store.dispatch('users/addUser', {user: obj})
            this.progress = false
            this.$emit('closeSidebar')

            this.$vs.notify({
              color: 'success',
              title: 'Usuario',
              text: 'Usuario creado correctamente.'
            })
          } catch (e) {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          }
        }
      })
    },
    initValues() {
      if (this.data.id) return
      this.user.displayName = null
      this.user.email = null
      this.user.password = null
      this.user.role = null
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
